@import "../constants";
.modal-wrap {

}

.modal-header {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  background: #E4E4E4;
  border: 1px solid #E4E4E4;
}

.modal-form {
  padding: 20px 30px 30px 30px;
}

.note {
  text-align: center;
}

.report-date {
  padding: 0 15px;
  width: 100%;
  height: 34px;
}

.spend-time {
  padding: 0 15px;
  width: 100%;
  height: 34px;
  background-color: rgb(255, 255, 255) !important;;
  &:hover,&:focus, &:active{
    background-color: rgb(255, 255, 255) !important;;
    border: 1px solid $mainBlue !important;
  }
}
input.spend-time:-webkit-autofill  {
  background-color: rgb(255, 255, 255) !important;
  background-image: none !important;
  color: rgb(0, 0, 0) !important;
}

.form-item{
  width: 100%;
  margin-top: 10px;
  border: 1px solid #CAC9C9;
  box-sizing: border-box;
  border-radius: 7px;
  transition: border 0.2s ease-in-out;
  background: white !important;
  &:hover,&:focus, &:active{
    background-color: white !important;
    border: 1px solid $mainBlue !important;
  }
}
.report-text {
  outline:none;
  width: 100%;
  padding: 0 15px;
  display: block;
  &::-webkit-scrollbar{
    width: 0;
  }
}
.button-wrap{
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.report-done{
  padding: 0;
  margin-top: 20px;
  font: inherit;
  cursor: pointer;
  width: 150px;
  height: 40px;
  background: $mainBlue;
  border: 1px solid $mainBlue;
  border-radius: 10px;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #FFFFFF;
  transition: background-color 0.2s ease-in-out;
  &:hover{
    background-color: lighten($mainBlue, 5%);
  }
}

.close-button{
  position: absolute;
  right: 15px;
  font-size: 20px;
  cursor:pointer;
}
.error{
  color: $error;
  font-size: 13px;
}
.error-border{
  border: 1px solid $error;
  background-color: white;
  &:hover{
    border: 1px solid $error !important;
  }
}
