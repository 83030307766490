@import "constants";
.report {
  &-wrap {
    flex: 1 0 auto;
  }
  &-block {
    margin-top: 80px;
  }
  &-filters {
    padding: 0;
    margin-bottom: 2rem;
  }
  &-loader {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &-calendar-widget {
    background: rgba($mainBlue, 0.2);

  }
  &-calendar-sticky {
    position: sticky;
    top: 8%;
    z-index: 2;
  }
}

.pagination-wrapper{
  display: flex;
  justify-content: center;
}
