.public-DraftEditor-content {
  box-sizing: border-box;
  cursor: text;
  padding: 16px;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  background: #fff;
  border-radius: 6px;
  border: 1px solid #E6E4EE;
  color: #404040;
  min-height: 124px;
  resize: vertical;
  -webkit-transition: all 300ms;
  -moz-transition: all 300ms;
  -o-transition: all 300ms;
  transition: all 300ms;
  outline: none;
  width: 100%;
}

.public-DraftEditor-content:focus {
  border: 1px solid #6a6a6a;
}

.editor :global(.public-DraftEditor-content) {
  min-height: 140px;
}

.options {
  margin-bottom: 10px;
}
