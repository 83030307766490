@import "../constants";
.report-calendar {
  position: relative;

  &__btn {
    position: absolute;
    top: 25%;
    color: $mainBlue;
    background: transparent;
    border-radius: 5px;
    width: 2rem;
    height: 2rem;
    border: 2px solid currentColor;

    &:disabled {
      color: $firsGray;
    }
  }
  &__prev-btn {
    left: -4%;

    &:after {
      content: '';
      position: absolute;
      display: block;
      height: 120%;
      width: 1px;
      background: rgba(0, 0, 0, 0.15);
      top: -10%;
      right: -80%;
    }
  }
  &__next-btn {
    right: -4%;
  }
}

.slide {
  &__wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 5px;

    &:after {
      content: '';
      width: 1px;
      height: 50%;
      position: absolute;
      background: rgba(0, 0, 0, 0.15);
      right: 0;
    }
  }

  &__day {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: none;
    background-color: transparent;
    text-transform: capitalize;
    transition: all .2s ease-in;
    padding: 5px 0;

    &--disabled {
      color: $firsGray;
      pointer-events: none;
      cursor: not-allowed;;
    }
  }

  &__month{
    font-size: 0.7rem;
  }

  &__day {
    font-size: 1.2rem;
    margin: 0;
  }
}
