.page-image {
  width: 100px;
  height: 100px;
  margin-left: 20px;
}

.quote-info {
  color: rgb(243, 240, 240);
  font-style: italic;
  font-size: 15px;
}