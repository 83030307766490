.all-course-wrap {
  flex: 1 0 auto;
}

.course-block {
  min-height: 177px;
}

.finish-course-block {
  display: none;
}

.finish-course {
  text-align: center;
  top: 10px;
  width: 27%;
  left: 22px;
  position: absolute;
  font-family: arial, sans-serif;
  font-size: 11px;
  font-weight: bold;
  color: rgb(68, 68, 68);
  text-decoration: none;
  user-select: none;
  padding: .2em 1.2em;
  outline: none;
  border: 1px solid rgba(0, 0, 0, .1);
  border-radius: 5px;
  background: rgb(245, 245, 245) linear-gradient(#f4f4f4, #f1f1f1);
  transition: all .218s ease 0s;
}

.finish-course:hover {
  color: rgb(24, 24, 24);
  border: 1px solid rgb(198, 198, 198);
  background: #f7f7f7 linear-gradient(#f7f7f7, #f1f1f1);
  box-shadow: 0 1px 2px rgba(0, 0, 0, .1);
}

.finish-course:active {
  color: rgb(51, 51, 51);
  border: 1px solid rgb(204, 204, 204);
  background: rgb(238, 238, 238) linear-gradient(rgb(238, 238, 238), rgb(224, 224, 224));
  box-shadow: 0 1px 2px rgba(0, 0, 0, .1) inset;
}
