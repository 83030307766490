.student-review {
  padding: 10px 30px 50px 30px;
}

.table-review-style {
  /*font-family: Roboto, serif;*/
  font-style: normal;
  overflow: hidden;
  table-layout: fixed;
  border: 1px solid #c8c8c8;
  background: #c8c8c8;
  width: 100%;
  -moz-border-radius: 10px; /* FF1+ */
  -webkit-border-radius: 10px; /* Saf3-4 */
  border-radius: 10px;
  -webkit-box-shadow: 0 0 1px rgba(36, 36, 36, 0.2);
}

.module-title-col {
  width: 35%;
}

.date-col {
  width: 25%;
}

.table-header-review, .table-data-review {
  padding: 10px 0 10px;
  text-align: center;
}

.table-header-review, .table-data-review {
  border-right: 2px solid #e0e0e0;
}

.table-data-review {
  font-size: 16px;
}

.table-header-review {
  font-size: 20px;
  font-weight: 500;
  background-color: #e1e9f4;
  padding-top: 15px;
}

.table-row-review:nth-child(odd) {
  background-color: #f6f6f6;
}

.table-row-review:nth-child(even) {
  background-color: #eceff4;
}
