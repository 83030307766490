.status-ok {
  font-size: 30px;
  color: #01d536;
  vertical-align: -0.2em;
  padding-right: 1px;
  /*padding-left: 30px;*/
}

.status-future {
  font-size: 30px;
  color: #ff0000;
  vertical-align: -0.17em;
  padding-right: 5px;
}

.status-fail {
  color: #ffac30;
  font-size: 30px;
  vertical-align: -0.17em;
  padding-right: 5px;
}

.cursor-hand {
  cursor: pointer;
}

.link-to-review {
  color: #0f0f0f;
  text-decoration: underline;
}



