@import "../../constants";
.edit-menu-btn{
  position: absolute;
  font-size: 19px;
  right: 15px;
}
.edit-buttons{
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
  background-color: white;
  border-radius: 10px;
  row-gap: 5px;
  font-size: 13px;
}
.edit-btn{
  display: flex;
  justify-content: flex-start;
  transition: color .2s ease-in;
  &:hover{
    color: $mainBlue;
  }
}
.btn{
  padding: 0;
  border: none;
  font: inherit;
  background-color: transparent;
  cursor: pointer;
}
