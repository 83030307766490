.details-title {
  width: 95%;
  font-size: 18px !important;
  padding-left: 5%;
  font-weight: bold;
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  float: left;
  height: 60px;
  /*font-family: Roboto, serif;*/
  font-style: normal;
}

.details-header {
  width: 100%;
  background-color: white;
}

.details-box {
}

.details-body {
  padding: 5px 30px;
}

.detail-body-header {
  display: inline-block;
  margin-left: 50%;
  transform: translateX(-50%);
  font-size: 18px;
  text-align: center;
  padding-top: 1px;
  font-style: normal;
  font-weight: normal;
  align-items: center;
}

.detail-body-date {
  padding-top: 15px;
  padding-right: 1px;
  /*font-family: Roboto, serif;*/
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  align-items: center;
  text-align: center;
}

.close-button-details {
  width: 5%;
  font-size: 25px;
  float: left;
  height: 50px;
  text-align: right;
  padding-right: 10px;
  padding-top: 2px;
  cursor: pointer;
}

.details-block {
  height: 100%;
  overflow: hidden;
  background-color: #f6f6f6;
}

.details-outside {
  height: 100%;
  padding-right: 0;
  overflow: hidden;
}

.details-questions {
  font-size: 18px;
  font-style: normal;
  width: 100%;
  padding: 8px;
}

.details-task {
  font-size: 18px;
  text-align: right;
  width: 100%;
  padding: 8px;
}

.details-wrapper {
  width: 100%;
}

.details-data-header {
  background: #ECEFF4;
  border: 1px solid #BFBFBF;
  text-align: center;
  /*font-family: Roboto, serif;*/
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  align-items: center;
  padding-top: 11px;
  padding-bottom: 11px;

}

.details-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  margin: 0 0;
}

.details-data-container {
  padding: 20px;
  display: flex;
  justify-content: space-around;
  height: 100%;
  overflow-y: auto;
}



.details-data {
  background: #FFFFFF;
  border-bottom: 1px solid #BFBFBF;
  border-left: 1px solid #BFBFBF;
  border-right: 1px solid #BFBFBF;
  text-align: left;
  /*font-family: Roboto, serif;*/
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  align-items: center;
  padding: 11px 20px;
}
