.review-wrap {
  flex: 1 0 auto;
}

.middle-header.review {
  padding-bottom: 50px;
}


.middle-header.review .page-title-wrap .page-title {
  font-size: 35px !important;
}

.review-block {
  padding-top: 15px;
}


.review-block .header-review {
  color: #0f0f0f;
  font-size: 20px;
  font-weight: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  /*font-family: Roboto, serif;*/
  font-style: normal;
  padding-bottom: 20px;
  padding-top: 1px;
}

.review-block {
  background: #e5ecf7;
  border-radius: 10px;
  margin-top: 50px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}

.review-table {
  background-color: white;
  border-radius: 0 0 10px 10px;
}

.filter-review {
  display: flex;
  font-size: 16px;
  margin: 5px 0;
  /*font-family: Roboto, serif;*/
  font-style: normal;
  font-weight: normal;
  align-items: center;
}

.review-date-input-block span{
  width: max-content;
}

.filter-block {
  justify-content: space-between;
  flex-wrap: wrap;
  display: flex;
  padding: 0 40px;
  padding-bottom: 10px;
  padding-top: 10px;
  /*font-family: Roboto, serif;*/
  font-style: normal;
  font-weight: normal;
  font-size: 16px;

}

.review-block:after {
  content: "";
  display: block;
  position: absolute;
  right: 25px;
  top: 20px;
  height: 66px;
  width: 94px;

}

.review-date-input-block{
  margin: 5px 0;
  align-items: center;
  display: flex;
}

.form-control-review {
  height: 100%;
  padding: 1px;
  font-size: 16px;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}

.combo-box-review {
  padding-right: 30px;
  padding-left: 10px;
  text-align: center;
  align-items: center;
  height: 30px;
}


.between-date {
  display: flex;
  font-size: 16px;
  /*font-family: Roboto, serif;*/
  font-style: normal;
  font-weight: normal;
  align-items: center;
  flex-wrap: wrap;
}

.start-date {
  padding-left: 10px;
  padding-right: 10px;
  font-size: 14px;
  height: 34px;
}

.end-date {
  font-size: 14px;
  padding-left: 10px;
  padding-right: 10px;
  height: 34px;
}

.date-picker {
  width: 120px;
  height: 29px;
  /*font-family: Roboto, serif;*/
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-align: right;
  border: 1px solid #AAAAAA;
  border-right-width: 0;
}
