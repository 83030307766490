.confirm-title {
  width: 95%;
  font-size: 20px !important;
  padding-left: 5%;
  font-weight: bold;
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  float: left;
  height: 60px;
}

.confirm-header {
  overflow: hidden;
}

.box {
  height: 50px;
  width: 100%;
}

.confirm-body {
  background-color: #f6f6f6;
  display: table;
  width: 100%;
  padding-top: 20px;
  padding-left: 30px;
  padding-right: 30px;
}

.text-style {
  font-size: 18px;
}

.close-button {
  width: 5%;
  font-size: 25px;
  float: left;
  height: 50px;
  text-align: right;
  padding-right: 5px;
  padding-top: 2px;
  cursor: pointer;
}

.footer-confirm {
  background-color: #f6f6f6;
  height: 70px;
  text-align: right;
  padding-right: 3px;
}

.confirm-button {
  cursor: pointer;
  color: white;
  background: #fa7b7b;
  border: 0;
  border-radius: 10px;
  font-size: 18px;
  width: 180px;
  height: 45px;
  margin-right: 2rem;
}

