.notification-overlay {
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-self: center;
  align-items: center;
}

.notification-modal {
  outline: none;
  margin: auto;
  max-width: 650px;
  background: white;
  padding: 25px;
  text-align: center;
  border: 1px solid #e3e2e2;
  box-shadow: 0 20px 75px rgba(0,0,0, .1);
  border-radius: 10px;
}

.notification-modal_title {
  margin: 0 0 20px 0;
  padding: 0;
}

.notification-modal_link {
  display: block;
  margin-bottom: 20px;
  width: 100%;
}

.notification-modal_about {
  text-align:justify;
}

.notification-modal_about p {
  text-indent: 20px;
}

.notification-modal_inner-wrapper {
  width: 90%;
  margin: 0 auto;
}

.notification-modal_about--error {
  color: red;
}

.notification-modal_close-btn {
  background-image: linear-gradient(-48deg, #4b8aff 0%, #407ef2 100%);
  border-radius: 5px;
  border: none;
  color: #fff;
  display: block;
  font-size: 18px;
  margin: 24px auto 0 auto;
  padding: 8px 24px;
  text-align: center;

}
