.fake-page-mentor-link {
    border: 1px solid darkgrey;
    border-radius: 5px;
    padding: 10px;
}

.badge-success {
    background-color: #01D536!important;
}

.badge-danger {
    background-color: #e52528!important;
}

.checkbox-task-group {
    width: 100%;
    text-align: left!important;
    font-size: 14px!important;
}

.checkbox-task-label {
    padding-left: 15px;
}

.mentor-check-container {
    margin-left: 20px;

}

#mentor-link {
    border: 1px solid darkgrey;
    border-radius: 5px;
    padding: 10px;
}

#mentor-comment {
    white-space: pre-wrap;
    resize: vertical;
}
.allComments {
    margin-top: 25px;
    font-size: 20px;
    font-weight: 700;
    display: inline-block;
    margin-right: 32px;
}

.no-solution-history {
  font-size: 20px;
  font-weight: 700;
  display: inline-block;
}

.exemplary-solution {
    cursor: pointer;
    position: relative;
    left: 30px;
    color: #4589FD;
    font-size: 18px;
    display: inline-block;
    right: 0;
}

.right {
  display: flex;
  justify-content: flex-end;
}
