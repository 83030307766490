$primaryBlue: #7AABFF;
$secondaryBlue: #DFEBFF;

$primaryRed: #FF3B5E;
$secondaryRed: #FFDFDF;

$primaryGreen: #00C832;
$secondaryGreen: rgba(111, 207, 151, 0.3);

.wrap {
  display: flex;
  justify-content: space-between;
}

.items {
  width: 45%;

  .item {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &_wide {
    width: 60%;
  }

  &_narrow {
    width: 30%;
  }
}

.item {
  display: flex;
  min-height: 38px;
  &__designation {
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 38px;
    padding: 7px 0;
    width: 38px;
    border: 1px solid $primaryBlue;
    background-color: #fff;
    border-radius: 4px 0 0 4px;
    font-size: 16px;

    &_wide {
      width: 50%;
      justify-content: flex-start;
      padding-left: 12px;
    }
  }

  &__label {
    display: flex;
    align-items: center;
    min-height: 38px;
    width: 100%;
    background-color: #fff;
    border: 1px solid $primaryBlue;
    padding: 7px 12px;
    color: #000;
    font-size: 16px;
    border-radius: 0 4px 4px 0;
  }

  &_empty {
    .item__label {
      background-color: #F1F1F1;
      border: 1px solid #E7E7E7;
    }
  }

  &_disabled {
    .item__label {
      background-color: #F5F5F5;
      border: 1px solid #E7E7E7;
      color: #D5D5D5;
    }
  }

  &_filled {
    .item__label {
      background-color: $secondaryBlue;
      border-left: none;
      padding: 0 12.5px;
    }

    .item__designation {
      background-color: $secondaryBlue;
    }
  }

  &_error {
    .item__label {
      background-color: $secondaryRed;
      border: 1px solid $primaryRed;
      border-left: none;
      padding: 0 12.5px;
    }

    .item__designation {
      background-color: $secondaryRed;
      border: 1px solid $primaryRed;
    }
  }

  &_success {
    .item__label {
      background-color: $secondaryGreen;
      border: 1px solid $primaryGreen;
      border-left: none;
      padding: 0 12.5px;
    }

    .item__designation {
      background-color: $secondaryGreen;
      border: 1px solid $primaryGreen;
    }
  }

  &_active {
    .item__label {
      background-color: $secondaryBlue;
    }
  }

  &.item_filled.item_active {
    .item__label {
      opacity: 0.4 !important;
    }
  }
}
