.moduleBtnDone {
  display: inline-block;
  text-decoration: none;
  background-color: #4b8dff;
  color: #ffffff;
  border: 2px solid #4b8dff;
  border-radius: 5px;
  font-size: 20px;
  padding: 2px 20px;
  transition: all 0.2s ease;
}
.moduleBtnDone:hover {
  text-decoration: none;
  background-color: #0163ff;
  color: #ffffff;
  border-color: #0163ff;
}

.moduleBtnReset {
  display: inline-block;
  text-decoration: none;
  background-color: #ffffff;
  color: #4b8dff;
  border: 2px solid #4b8dff;
  border-radius: 5px;
  font-size: 20px;
  padding: 2px 20px;
  transition: all 0.2s ease;
}
.moduleBtnReset:hover {
  text-decoration: none;
  background-color: #4b8dff;
  color: #ffffff;
  border-color: #4b8dff;
}

.iconWrapper {
  background-color: #909090;
  color: #fff;
  border-radius: 50%;
  padding: 0 4px;

  &.onCheck {
    background: #ffcb00;
    color: #fff;
  }

  &.completed {
    background: #01d536;
    color: #fff;
  }

  &.unRight {
    background: #e52528;
    color: #fff;
  }
  &.right {
    background: #01d536;
    color: #fff;
  }
}
