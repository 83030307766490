.comment-btn {
  margin-left: 20px;
  background: none;
  border: bisque;
  color: grey;
  font-size: 14px;
  cursor: pointer;
}

#comments-list {
  padding-left: 0;
  /* padding-top: 20px; */
}

#comments-list .panel-title {
  text-align: left;
}

#comments-list .panel-heading {
  background: none !important;
  border-bottom: 1px solid gainsboro !important;
  padding-bottom: 0;
  padding-top: 0;
}

#comments-list .panel-body {
  padding: 5px 15px;
}

#comments-list .panel {
  border: none;
}

.comments-container {
  margin: 0;
  width: 100%;
}

.comments-container h1 {
  font-size: 36px;
  color: #283035;
  font-weight: 400;
}

.comments-container h1 a {
  font-size: 18px;
  font-weight: 700;
}

.comments-container ul {
}

.comments-list li {
  margin-bottom: 15px;
  display: block;
  position: relative;
}

.comments-list li:after {
  content: '';
  display: block;
  clear: both;
  height: 0;
  width: 0;
}

.reply-list {
  padding-left: 88px;
  clear: both;
  margin-top: 15px;
}


.comment-main-level:after {
  content: '';
  width: 0;
  height: 0;
  display: block;
  clear: both;
}

.comment-main-level .comment-box {
  width: 100%;
  float: right;
  position: relative;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
}

.comments-list .comment-box:before, .comments-list .comment-box:after {
  content: '';
  height: 0;
  width: 0;
  position: absolute;
  display: block;
  border-width: 10px 12px 10px 0;
  border-style: solid;
  border-color: transparent #FCFCFC;
  top: 8px;
  left: -11px;
}

.comments-list .comment-box:before {
  border-width: 11px 13px 11px 0;
  border-color: transparent rgba(0, 0, 0, 0.05);
  left: -12px;
}

.reply-list .comment-box {
  width: 610px;
}

.comment-box .comment-head {
  background: #FCFCFC;
  padding: 100px 12px;
  border-bottom: 1px solid #E5E5E5;
  overflow: hidden;
  -webkit-border-radius: 4px 4px 0 0;
  -moz-border-radius: 4px 4px 0 0;
  border-radius: 4px 4px 0 0;
}

.comment-box .comment-head i {
  float: right;
  margin-left: 14px;
  position: relative;
  top: 2px;
  color: #A6A6A6;
  cursor: pointer;
  -webkit-transition: color 0.3s ease;
  -o-transition: color 0.3s ease;
  transition: color 0.3s ease;
}

.comment-box .comment-head i:hover {
  color: #03658c;
}

.comment-box .comment-name {
  color: #283035;
  font-size: 14px;
  font-weight: 700;
  float: left;
  margin-right: 10px;
}

.comment-box .comment-name a {
  color: #283035;
}

.comment-box .comment-head span {
  float: left;
  color: #999;
  font-size: 13px;
  position: relative;
  top: 1px;
}

.comment-box .comment-content {
  background: #FFF;
  padding: 12px;
  font-size: 15px;
  color: #595959;
  -webkit-border-radius: 0 0 4px 4px;
  -moz-border-radius: 0 0 4px 4px;
  border-radius: 0 0 4px 4px;
}

.comment-box .comment-name.by-author, .comment-box .comment-name.by-author a {
  color: #03658c;
}

.comment-box .comment-name.by-author:after {
  content: 'autor';
  background: #03658c;
  color: #FFF;
  font-size: 12px;
  padding: 3px 5px;
  font-weight: 700;
  margin-left: 10px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

.comments-container h1 {
  font-size: 24px;
  color: #283035;
  font-weight: 400;
  margin: 15px;
}

.comments-container h1 a {
  font-size: 38px;
  font-weight: 700;
}

.comments-list {
  position: relative;
}

.comments-list li {
  margin-bottom: 15px;
  display: block;
  position: relative;
}

.reply-list {
  padding-left: 0px;
  clear: both;
  margin-top: 15px;
}

.comments-list .comment-box {
  float: right;
  position: relative;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
}

.comments-list .comment-box:before, .comments-list .comment-box:after {
  content: '';
  height: 0;
  width: 0;
  position: absolute;
  display: block;
  border-width: 10px 18px 10px 0px;
  border-style: solid;
  border-color: transparent #d6d5e8f5;
  top: 8px;
  left: -16px;
}

.comments-list .comment-box:before {
  border-width: 11px 13px 11px 0;
  border-color: transparent rgba(0, 0, 0, 0.05);
  left: -12px;
}

.reply-list .comment-box {
  width: 90%;
}

.comment-box .comment-head {
  background: #d6d5e8f5;
  padding: 12px 12px;
  border-bottom: 1px solid #E5E5E5;
  overflow: hidden;
  -webkit-border-radius: 4px 4px 0 0;
  -moz-border-radius: 4px 4px 0 0;
  border-radius: 4px 4px 0 0;
}

.comment-box .comment-head i {
  float: right;
  margin-left: 14px;
  position: relative;
  top: 2px;
  color: #A6A6A6;
  cursor: pointer;
  -webkit-transition: color 0.3s ease;
  -o-transition: color 0.3s ease;
  transition: color 0.3s ease;
}

.comment-box .comment-head i:hover {
  color: #03658c;
}

.comment-box .comment-name {
  color: #283035;
  font-size: 14px;
  font-weight: 700;
  float: left;
  margin: 0px 0px 0px 0px;
}

.comment-box .comment-name a {
  color: #283035;
}

.comment-box .comment-head span {
  float: right;
  color: #929292;
  font-size: 13px;
  position: relative;
}

.comment-box .comment-content {
  background: #FFF;
  padding: 12px 12px 5px 12px;
  font-size: 15px;
  color: #595959;
  -webkit-border-radius: 0 0 4px 4px;
  -moz-border-radius: 0 0 4px 4px;
  border-radius: 0 0 4px 4px;
}

.comment-box .comment-name.by-author, .comment-box .comment-name.by-author a {
  color: #03658c;
}

.comment-box .comment-name.by-author:after {
  content: 'autor';
  background: #03658c;
  color: #FFF;
  font-size: 12px;
  padding: 3px 5px;
  font-weight: 700;
  margin-left: 10px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

.comment-content .icon {
  padding: 0px 4px 8px 4px;
}

.comment-content .icon-edit {
  float: right;
}

.comment-content .icon-answer {
  float: right;
  transform: rotate(180deg);
  padding: 8px 4px 0px 4px;
}

.comment-content .icon-like {
  float: left;
}

.comment-content .clicked {
  color: red;
}

.comment-content .icons-line {
}

.comment-text, .comment-edit-form {
  padding-bottom: 10px;
}

.like-box, .edit-box {
  display: inline;
}

.comment-edit-form textarea {
  margin: 0;
}

a:hover {
  cursor: pointer;
}

.comment-text {
  word-wrap: break-word;
  white-space: pre-wrap;
  height: 100%;
}

.comment-text--collapse {
  height: auto;
  max-height: calc(20px * 5);
  overflow-y: hidden;
  position: relative;
}
.comment-text--collapse::after {
  content: "";
  position: absolute;
  left: 0;
  top: 70%;
  bottom: 0;
  width: 100%;
  background-image: linear-gradient(to bottom, transparent, #fff);
}

.text-area-comment {
  background: #fff;
  border-radius: 6px;
  border: 1px solid #E6E4EE;
  color: #404040;
  min-height: 124px;
  resize: vertical;
  -webkit-transition: all 300ms;
  -moz-transition: all 300ms;
  -o-transition: all 300ms;
  transition: all 300ms;
  outline: none;
  width: 100%;
}

.text-area-comment :focus {
  border: 1px solid #404040;
}

.icon-btn {
  background: transparent;
  color: #a1a1a1;
  border: none;
  margin-left: 7px;
  padding: 0;
}

.glyphicon-comments {
  top: 1px !important;
}

.emoji-image {
  width: 20px;
  height: 20px;
  background-size: 20px 20px;
  vertical-align: middle;
  margin: -3px 0 0 0;
}

.commentCollapse {
  background: none;
  border: none;
  padding: 0;
  font-size: 12px;
  color: #8b8b8b;
}

.commentCollapse:hover {
  text-decoration: underline !important;
}

.commentCollapse--hide {
  display: none;
}

.premoder-buttons-wrapper {
  float: left;
  display: flex;
  align-items: center;
  margin-left: 20px;
}

.premoder-button__submit,
.premoder-button__delete {
  width: 20px;
  height: 20px;
  border: none;
  font-family: inherit;
  background-color: transparent;
  background-position: center;
  border-radius: 5px;
}

.premoder-button__submit {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' style='width:24px;height:24px' viewBox='0 0 24 24'%3E%3Cpath fill='green' d='M9,20.42L2.79,14.21L5.62,11.38L9,14.77L18.88,4.88L21.71,7.71L9,20.42Z' /%3E%3C/svg%3E");
  margin-right: 7px;
}
.premoder-button__submit:hover {
  background-color: #acecbc;
}

.premoder-button__delete {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' style='width:24px;height:24px' viewBox='0 0 24 24'%3E%3Cpath fill='red' d='M20 6.91L17.09 4L12 9.09L6.91 4L4 6.91L9.09 12L4 17.09L6.91 20L12 14.91L17.09 20L20 17.09L14.91 12L20 6.91Z' /%3E%3C/svg%3E");
}

.premoder-button__delete:hover {
  background-color: #edafaf;
}
