@import "../constants";

.report {
  display: flex;
  min-height: 210px;
  background: #FFFFFF;
  box-sizing: border-box;
  padding: 25px;
  transition: .02s ease-in;
  gap: 1rem;
  &:hover{
    border-color: $mainBlue;
  }

  &__avatar {
    width: 70px;
    height: 70px;
    border-radius: 50%;
  }

  &__logo {
    width: inherit;
    height: inherit;
    border-radius: 50%;
    object-fit: cover;
    object-position: 50% 50%;
  }

  &__name {
      font-size: 20px;
      line-height: 1.2;
  }

  &__birthday {
    margin-top: .3rem;
    margin-bottom: 1rem;
    font-size: .8rem;
    line-height: 1.2;
    color: #747474;
  }

  &__spend-time,
  &__task-progress {
    margin: 0;
    font-size: 1.1rem;
  }

  &__hide {
    padding: .5rem 0 ;
    border: none;
    cursor: pointer;
    font: inherit;
    background-color: transparent;
    color: $mainBlue;
    font-weight: bold;
    transition: .02s ease-in;

    &:hover {
      opacity: .8;
    }
  }
}

