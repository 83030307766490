.start-page .logo {
  margin: 16px auto;
  max-width: 160px
}

.start-page {
  display: grid;
  grid-template-columns: 1fr 475px 1fr;
  grid-template-rows: 160px 150px 1fr;
  height: 100%;
}

.start-page .block-logo {
  grid-column: 2;
  grid-row: 1;
  margin: 0;
  padding: 0;
  width: 100%;
  display: grid;
  justify-self: center;
}

.start-page .block-title {
  grid-column: 2;
  grid-row: 2;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.start-page .block-title .title {
  font-size: x-large;
}

.start-page .block-title .subtitle {
  font-size: xx-large;
  font-weight: lighter;
}

.start-page .block-data {
  grid-column: 2;
  grid-row: 3;
  margin: 0;
  padding: 0;
  width: 100%;
  display: grid;
  grid-template-rows: repeat(6, 1fr);
}

.start-page .label-for-input {
  margin: 0 0 8px 0;
  font-size: 16px;
  font-weight: 400;
}

.start-page .input-data {
  margin: 0;
}

.start-page .block-date {
  display: grid;
  grid-template-columns: 1fr 4fr 2fr;
  column-gap: 10px;
}

.start-page #day {
  margin: 0;
}

.start-page #month {
  margin: 0;
}

.start-page #year {
  margin: 0;
}

.start-page .block-data .form-group {
  margin: 0;
}

.start-page #div_error {
  margin: 0 0 10px 0;
}

.start-page #invite {
  margin-top: 15px;
  width: 100%;
  height: 35px;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
