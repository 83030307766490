$link-color: #4b8aff;

.wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -55%);
  max-width: 480px;
  min-width: 370px;
  width: 100%;
  padding: 50px 50px 40px 50px;
  background-color: #FFFFFF;
}

.logo {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 32px;
}

.title {
  font-size: 28px;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 28px;
  text-align: center;
  color: #404040;
}

.input {
  border: 1px solid #D9D9D9;
  color: #404040;
  font-size: 18px;
  font-weight: 300;
  margin-bottom: 15px;
  padding: 10px 15px;
  width: 100%;
  transition: all 200ms;

  &:focus {
    border: 1px solid $link-color;
  }

  &:last-of-type {
    margin-bottom: 25px;
  }
}

.label {
}

.checkbox {
  display: flex;
  align-items: center;
  color: #404040;
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 35px;
}


input.checkbox__input {
  appearance: none;
  margin: 0!important;
  display: inline-flex;
  align-items: center;

  &::after {
    content: "";
    width: 20px;
    height: 20px;
    margin-right: 12px;
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='1' y='1' width='18' height='18' rx='4' fill='white' stroke='%23B1AEAE' stroke-width='2'/%3E%3C/svg%3E%0A");
  }

  &:focus::after {
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='1' y='1' width='18' height='18' rx='4' fill='%234B8AFF' fill-opacity='0.2' stroke='%234B8AFF' stroke-width='2'/%3E%3C/svg%3E%0A");
  }

  &:checked::after {
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='1' y='1' width='18' height='18' rx='4' fill='%234B8AFF' fill-opacity='0.2' stroke='%234B8AFF' stroke-width='2'/%3E%3Cline x1='4.91024' y1='11.4307' x2='8.52896' y2='15.1804' stroke='%234B8AFF' stroke-width='2' stroke-linecap='round'/%3E%3Cline x1='15.9816' y1='4.84704' x2='8.65623' y2='15.3007' stroke='%234B8AFF' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E%0A");
  }
}

.button {
  background-color: $link-color;
  border-radius: 10px;
  border: none;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  height: 60px;
  width: 100%;
  margin-bottom: 15px;
  transition: all .1s linear;

  &:hover, &:focus {
    background-color: #437ce5;
    font-size: 20px;
  }
}

.link__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
}

.link {
  display: inline-block;
  justify-self: center;
  border-bottom: 1px dashed rgba(100,83,199,.5);
  color: $link-color;
  font-size: 18px;
  font-weight: 600;
  transition: all .2s;
}

.password__wrapper {
  position: relative;
}

.toggle__password {
  border: none;
  background: none;
  position: absolute;
  width: 20px;
  height: 20px;
  top: 13px;
  right: 22px;
  opacity: 0.5;
  background-repeat: no-repeat;
  background-size: contain;

  &:hover {
    opacity: 1;
  }
}

.input[type="password"] + .toggle__password {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0wIDguMDAzYzAtMS44IDIuODU3LTYgOC02czggNC4yIDggNmMwIDEuOC0yLjg1NyA2LTggNnMtOC00LjItOC02em0yIDBjMCAuMzYyLjU0NiAxLjM3NyAxLjQ0IDIuMjE3IDEuMTkzIDEuMTIgMi43NSAxLjc4MyA0LjU2IDEuNzgzIDEuODEgMCAzLjM2Ny0uNjYzIDQuNTYtMS43ODMuODk0LS44NCAxLjQ0LTEuODU1IDEuNDQtMi4yMTcgMC0uMzYyLS41NDYtMS4zNzctMS40NC0yLjIxN0MxMS4zNjYgNC42NjYgOS44MSA0LjAwMyA4IDQuMDAzYy0xLjgxIDAtMy4zNjcuNjYzLTQuNTYgMS43ODNDMi41NDcgNi42MjYgMiA3LjY0IDIgOC4wMDN6bTguMyAwYTIuMyAyLjMgMCAxMS00LjYgMCAyLjMgMi4zIDAgMDE0LjYgMHoiIGZpbGw9IiMyMjI0MjYiLz48L3N2Zz4=");
}

.input[type="text"] + .toggle__password {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0yLjUxOSAzLjkzM0wuNzkzIDIuMjA3QTEgMSAwIDAxMi4yMDcuNzkzbDEzIDEzYTEgMSAwIDAxLTEuNDE0IDEuNDE0bC0yLjA0MS0yLjA0MUE4LjYzNCA4LjYzNCAwIDAxOCAxNGMtNS4xNDMgMC04LTQuMi04LTYgMC0uOTk0Ljg3Mi0yLjcyIDIuNTE5LTQuMDY3em0xLjQyMyAxLjQyM2MtLjE3NC4xMzQtLjM0LjI3Ni0uNTAxLjQyN0MyLjU0NiA2LjYyMyAyIDcuNjM4IDIgOGMwIC4zNjIuNTQ2IDEuMzc3IDEuNDQgMi4yMTdDNC42MzQgMTEuMzM3IDYuMTkgMTIgOCAxMmMuNzkyIDAgMS41MzYtLjEyNyAyLjIyMi0uMzY0bC0xLjQ2NC0xLjQ2NGEyLjMgMi4zIDAgMDEtMi45My0yLjkzTDMuOTQyIDUuMzU2ek0xNCA4YzAgLjI3Ni0uMzE4LjkzMS0uODY1IDEuNmwxLjQyIDEuNDJDMTUuNTA1IDkuOTE1IDE2IDguNzUgMTYgOGMwLTEuOC0yLjg1Ny02LTgtNi0uNzg1IDAtMS41MTcuMDk4LTIuMTk0LjI3bDEuNzQzIDEuNzQ0QzcuNjk4IDQuMDA0IDcuODQ4IDQgOCA0YzEuODEgMCAzLjM2Ny42NjMgNC41NiAxLjc4M0MxMy40NTMgNi42MjMgMTQgNy42MzggMTQgOHoiIGZpbGw9IiMyMjI0MjYiLz48L3N2Zz4=");
}

@media screen and (max-width: 425px) {
  .wrapper {
    transform: none;
    top: 0;
    left: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 0;
    box-shadow: none;
  }
}
