.wrapper {
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.button {
    padding: 16px 32px;
    font-family: helvetica;
    font-size: 16px;
    font-weight: 100;
    color: #fff;
    background: #587286;
    border: 0;
}

.button:hover {
    background: #3B5C76;
    cursor: pointer;
}
