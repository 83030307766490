.inputItem {

  margin-bottom: 25px;

  .success {
    border-color: #00C832;
  }

  .error {
    border-color: #FF264D;
  }

  .inputField {
    margin-top: 0 !important;

  }
}
