.notification {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0;
}

.notification:not(:last-of-type) {
  border-bottom: 1px solid #e8e6ef;
}

.notification__title {
  position: relative;;
  color: #404040;
  font-size: 16px;
  font-weight: bold;
  margin: 0;
  cursor: pointer;
  transition: color .1s ease-in;
}

.notification__title_showed {
  position: relative;
  color: #404040;
  font-size: 16px;
  font-weight: normal;
  margin: 0;
  cursor: pointer;
  transition: color .1s ease-in;
}

.notification__title:hover {
  color: #818181;
}

.notifications-wrapper {
  max-height: 400px;
  overflow-y: scroll;
}

.notification__content-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.notification__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: end;
}

.notification__icon {
  display: grid;
  place-items: center;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  background: #8db5ff;
  color: #fff;
  font-size: 24px;
  margin-right: 1rem;
}

.notification__date-time {
  font-size: 0.8rem;
  color: #BEBEBE;
  align-self: end;
}

.notification__close {
  display: none;
  background: transparent;
  border: none;
  padding: .5rem;
  cursor: pointer;
  transition: color .3s ease-in;
}

.notification__close:hover {
  color: #fc7777;
}
