.report-list {
  margin-top: 20px ;
  margin-bottom: 20px;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  justify-content: flex-start;
  column-gap: 20px;
  position: relative;
}

.report-not-found {
  display: flex;
  min-height: 200px;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.report-message {
  background: #e5ecf7;
  padding: 15px 30px;
  border-radius: 5px;
}

.report-empty {
  min-height: 210px;
  max-width: 236px;
  background: #F6F6F6;
  box-sizing: border-box;
  padding: 25px;
}

.report-grid {
  display: grid;
  gap: 20px;
  overflow: hidden;
}

.report-row {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 20px;
  overflow-x: scroll;
}
