.birthday {
  background: none;
  border: 1px solid #D9D9D9;
  color: #404040;
  font-size: 18px;
  font-weight: 300;
  margin-bottom: 15px;
  padding-left: 15px;
  height: 46px;
  width: 100%;
  transition: all 200ms;
}
